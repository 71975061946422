import * as React from 'react';

const TelegramSocialSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' {...props} viewBox='0 0 100 100'>
    <path
      fill='#fff'
      d='M49.767 0A50.117 50.117 0 1 0 50 0h-.233Zm20.675 30.1a3.271 3.271 0 0 1 1.937.583 2.108 2.108 0 0 1 .713 1.354A8.755 8.755 0 0 1 73.175 34c-.75 7.908-4.008 27.092-5.667 35.946-.7 3.75-2.079 5-3.417 5.125-2.9.271-5.1-1.917-7.917-3.758-4.4-2.888-6.887-4.683-11.158-7.5-4.937-3.25-1.737-5.042 1.075-7.958.737-.767 13.529-12.4 13.779-13.458a1.027 1.027 0 0 0-.233-.883 1.16 1.16 0 0 0-1.037-.1q-.663.15-21.088 13.937a9.419 9.419 0 0 1-5.425 2 35.4 35.4 0 0 1-7.771-1.833c-3.133-1.021-5.621-1.558-5.4-3.287q.169-1.35 3.721-2.762Q44.5 39.946 51.792 36.912c13.883-5.775 16.771-6.779 18.65-6.812Z'
    />
  </svg>
);
export default TelegramSocialSvg;
